import axios from "axios";
import { cust_img_note, pricelist_items } from "../Component/dummydata";

const pos_pricelist_copr_protection = {
  id: 0,
  name: "Dry Cleaning",
  has_child: true,
  items: [
    {
      id: 0,
      name: "Shirts",
      items: [
        {
          item_id: 0,
          name: "OnHanger",
          cost: 5,
        },
        {
          item_id: 1,
          name: "Boxed",
          cost: 5,
        },
        {
          item_id: 2,
          name: "Hand Pressed",
          cost: 11.5,
        },
        {
          item_id: 3,
          name: "Dry Cleaned",
          cost: 12,
        },
        {
          item_id: 4,
          name: "Tuxedo",
          cost: 16,
        },
        {
          item_id: 5,
          name: "Polo Shirt / Sport",
          cost: 13,
        },
      ],
    },
    {
      id: 1,
      name: "Suits",
      items: [
        {
          item_id: 0,
          name: "Two Piece",
          cost: 27,
        },
        {
          item_id: 1,
          name: "Three Piece",
          cost: 33,
        },
        {
          item_id: 2,
          name: "Suit Jacket",
          cost: 16,
        },
        {
          item_id: 3,
          name: "Slacks",
          cost: 16,
        },
        {
          item_id: 4,
          name: "Tuxedo",
          cost: 33,
        },
        {
          item_id: 5,
          name: "Vest",
          cost: 13,
        },
        {
          item_id: 6,
          name: "Tie",
          cost: 10,
        },
      ],
    },
    {
      id: 2,
      name: "Accessories",
      items: [
        {
          item_id: 0,
          name: "Tie",
          cost: 10,
        },
        {
          item_id: 1,
          name: "Scarf",
          cost: 12,
        },
        {
          item_id: 2,
          name: "Shawl",
          cost: 15,
        },
        {
          item_id: 3,
          name: "Hat",
          cost: 8.5,
        },
        {
          item_id: 4,
          name: "Cummerbund",
          cost: 10,
        },
        {
          item_id: 5,
          name: "Socks",
          cost: 8,
        },
        {
          item_id: 6,
          name: "Gloves",
          cost: 8,
        },
        {
          item_id: 7,
          name: "Zitsis",
          cost: 10,
        },
        {
          item_id: 8,
          name: "2 Piece Pajama",
          cost: 18,
        },
      ],
    },
    {
      id: 3,
      name: "Tops",
      items: [
        {
          item_id: 0,
          name: "Blouse",
          cost: 15,
        },
        {
          item_id: 1,
          name: "Tank Top",
          cost: 15,
        },
        {
          item_id: 2,
          name: "Sweater",
          cost: 18,
        },
        {
          item_id: 3,
          name: "Cardigan",
          cost: 18,
        },
        {
          item_id: 4,
          name: "Hoodie",
          cost: 18,
        },
        {
          item_id: 5,
          name: "TShirt",
          cost: 12,
        },
        {
          item_id: 6,
          name: "Bodysuit",
          cost: 15,
        },
      ],
    },
    {
      id: 4,
      name: "Bottoms",
      items: [
        {
          item_id: 0,
          name: "Pants",
          cost: 16,
        },
        {
          item_id: 1,
          name: "Jeans",
          cost: 16,
        },
        {
          item_id: 2,
          name: "Skirts",
          cost: 16,
        },
        {
          item_id: 3,
          name: "Shorts",
          cost: 12,
        },
      ],
    },
    {
      id: 5,
      name: "Household",
      items: [
        {
          item_id: 0,
          name: "Tablecloth",
          cost: 30,
        },
        {
          item_id: 1,
          name: "Napkin",
          cost: 9,
        },
        {
          item_id: 2,
          name: "Bathrobe",
          cost: 20,
        },
        {
          item_id: 3,
          name: "Towel",
          cost: 15,
        },
        {
          item_id: 4,
          name: "Bathmat",
          cost: 15,
        },
        {
          item_id: 5,
          name: "Pillow Case",
          cost: 15,
        },
        {
          item_id: 6,
          name: "Fitted Sheet",
          cost: 30,
        },
        {
          item_id: 7,
          name: "Flat Sheet",
          cost: 30,
        },
        {
          item_id: 8,
          name: "Blanket",
          cost: 40,
        },
        {
          item_id: 9,
          name: "Duvet Cover",
          cost: 40,
        },
        {
          item_id: 10,
          name: "Comforter",
          cost: 55,
        },
        {
          item_id: 11,
          name: "Shower Curtain",
          cost: 25,
        },
      ],
    },
    {
      id: 6,
      name: "Dresses",
      items: [
        {
          item_id: 0,
          name: "One Piece",
          cost: 25,
        },
        {
          item_id: 1,
          name: "Two Piece",
          cost: 30,
        },
        {
          item_id: 2,
          name: "Cocktail Dress",
          cost: 30,
        },
        {
          item_id: 3,
          name: "Gown",
          cost: 30,
        },
        {
          item_id: 4,
          name: "Jumpsuit",
          cost: 25,
        },
        {
          item_id: 5,
          name: "Romper",
          cost: 25,
        },
      ],
    },
    {
      id: 7,
      name: "Outerwear",
      items: [
        {
          item_id: 0,
          name: "Trench Coat",
          cost: 40,
        },
        {
          item_id: 1,
          name: "Water Repellant (Extra)",
          cost: 30,
        },
        {
          item_id: 2,
          name: "Jacket",
          cost: 35,
        },
        {
          item_id: 3,
          name: "Parka",
          cost: 45,
        },
        {
          item_id: 4,
          name: "Coat",
          cost: 45,
        },
        {
          item_id: 5,
          name: "Raincoat",
          cost: 40,
        },
        {
          item_id: 6,
          name: "Peacoat",
          cost: 45,
        },
        {
          item_id: 7,
          name: "Duffle Coat",
          cost: 45,
        },
      ],
    },
  ],
};

const test = false;
const url = test ? "localhost:3000" : "api.montereyvalet.com";
const base_url = `http${test ? "" : "s"}://${url}/api/pos/v1/`;
// const base_url = `https://41d2-122-50-197-145.in.ngrok.io/api/pos/v1/`;
// const url = "localhost:9000";
// const base_url = `http://${url}/api/pos/v1/`;

// const url = "ff9a-122-50-197-91.in.ngrok.io";
// const url = "localhost:9000";
// const base_url = `http://${url}/api/pos/v1/`;
// const base_url = `https://b4ab-122-50-197-24.in.ngrok.io/api/pos/v1/`;
const api = {
  CheckToken: "verify-token",
  getClients: "clients",
  getClientById: "get-client",
  addClientCard: "add-card",
  addClientAddress: "add-address",
  EditClient: "edit-client",
  getOrder: "get-orders",
  CreateOrder: "create-order",
  getOrderById: "get-order-by-id",
  putOrderById: "put-order-by-id",
  base64ToLink: "base64-to-link",

  chargeOrder: "charge-order",
  startDelivery: "start-delivery",
  startPickup: "start-pickup",
  cancelOrder: "cancel-order",

  getCash: "get-cash",
  addCash: "add-cash",
  editCash: "edit-cash",

  getitems: "get-price-list",
  setitems: "put-price-list",
  getfaq: "get-faq",
  setfaq: "put-faq",

  getdiscount: "get-discount-list",
  setdiscount: "put-discount-list",

  getvendors: "get-vendors",
  editvendors: "edit-vendor",
  deletevendors: "delete-vendor",
  addvendors: "add-vendor",

  addteam: "add-team",
  getteam: "get-team",
  editteamdetails: "edit-team-details",
  deleteteampassword: "edit-team-password",
  deleteteam: "delete-team",
  // checkpasssword: "verify-passsword",
  checkpasssword: "verify",

  addclient: "add-client",
  addclientaddress: "add-client-w-addrress",
  addclientoptions: "add-client-w-options",

  chargeamount: "charge-pay-later",
};
export const mobile_convert_display = (phone_no) => {
  // return phone_no
  phone_no = `${phone_no}`;
  return `+1 (${phone_no.slice(1, 4)}) ${phone_no.slice(4, 7)}-${phone_no.slice(
    7
  )}`;
};

export const convert_to_am_pm = (date) => {
  let to_return = new Date(date).toString().split(" ").splice(1, 4);
  if (to_return.length != 4) return;
  let check_arr = to_return[3]?.split(":");
  check_arr.length = 2;
  to_return.push(["am"]);
  if (check_arr && check_arr[0] > 12) {
    check_arr[0] = check_arr[0] - 12;
    to_return[4] = ["pm"];
  }
  to_return[3] = check_arr.join(":");
  return to_return.join(" ");
};

export const checktoken = (token, callback) => {
  axios
    .post(
      `${base_url}${api.CheckToken}`,
      {},
      {
        headers: {
          authorization: token,
        },
      }
    )
    .then((response) => response.data)
    .then((response) => {
      if (response.error) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        admin: response.is_admin,
      });
    });
};

export const getclients = (token, callback) => {
  axios
    .post(
      `${base_url}${api.getClients}`,
      {},
      {
        headers: {
          authorization: token,
        },
      }
    )
    .then((response) => response.data)
    .then((response) => {
      if (response.error) {
        return callback({
          status: false,
        });
      }
      // response.clients.map((el) =>
      //   getclient_byid(el.id, token, (data) => {
      //   })
      // );
      return callback({
        status: true,
        data: response.clients,
      });
    });
};

export const getclient_byid = (id, token, callback) => {
  axios
    .post(
      `${base_url}${api.getClientById}`,
      {
        client_id: id,
      },
      {
        headers: {
          authorization: token,
        },
      }
    )
    .then((response) => response.data)
    .then((response) => {
      if (response.error) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        data: response,
      });
    });
};

export const addclientaddress = (add_obj, token, callback) => {
  axios
    .post(
      `${base_url}${api.addClientAddress}`,
      {
        ...add_obj,
      },
      {
        headers: {
          authorization: token,
        },
      }
    )
    .then((response) => response.data)
    .then((response) => {
      if (response.error) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        data: response,
      });
    });
};

export const addclientcard = (card_obj, token, callback) => {
  axios
    .post(
      `${base_url}${api.addClientCard}`,
      {
        ...card_obj,
      },
      {
        headers: {
          authorization: token,
        },
      }
    )
    .then((response) => response.data)
    .then((response) => {
      if (response.error == "Invalid Card") {
        return callback({
          status: true,
          error: "Invalid Card",
        });
      }
      if (response.error) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        data: response,
      });
    });
};

export const editclient = (client_obj, token, callback) => {
  axios
    .post(
      `${base_url}${api.EditClient}`,
      {
        ...client_obj,
      },
      {
        headers: {
          authorization: token,
        },
      }
    )
    .then((response) => response.data)
    .then((response) => {
      if (response.error) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        data: response,
      });
    });
};

export const getitems = (obj, token, callback) => {
  axios
    .post(`${base_url}${api.getitems}`)
    // .post(`http://localhost:9000/readfile`, { type: 1 })
    .then((data) => data.data)
    .then((data) => {
      callback([...JSON.parse(data)]);
      // callback([...data]);
    });
  // setTimeout(() => {
  //   callback([
  //     ...pricelist_items.map((el) => {
  //       return {
  //         category_id: el.id,
  //         item_category_name: el.name,
  //       };
  //     }),
  //   ]);
  // }, 500);
};
export const setitems = (data, token, callback) => {
  axios
    .post(
      `${base_url}${api.setitems}`,
      // `http://localhost:9000/writefile`,
      {
        type: 1,
        data: JSON.stringify(data),
      },
      {
        headers: {
          authorization: token,
        },
      }
    )
    .then((data) => data.data)
    .then((data) => {
      callback({
        status: true,
      });
    });
};

export const getsubcategory = (id, token, callback) => {
  // does not work as of now
  return [];
  setTimeout(() => {
    callback({ ...pricelist_items.filter((el) => el.id === id)[0] });
  }, 500);
};

export const createorder = (
  { client_id, address, apt, zipcode },
  token,
  callback
) => {
  // return {
  //   status: true,
  //   data: {
  //     order_id: "#ORDER1234",
  //   },
  // };
  axios
    .post(
      `${base_url}${api.CreateOrder}`,
      {
        client_id,
        address,
        apt,
        zipcode,
      },
      {
        headers: {
          authorization: token,
        },
      }
    )
    .then((response) => response.data)
    .then((response) => {
      if (response.error) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        data: response,
      });
    });
};
export const getorderbyid = (order_id, token, callback) => {
  // return callback({
  //   status: true,
  //   data: {
  //     order_id: order.order_id,
  //     status_order_data: [
  //       "04-04-2022 13:00",
  //       "04-04-2022 14:00",
  //       "04-04-2022 15:00",
  //       "04-04-2022 16:00",
  //       "04-04-2022 17:00",
  //     ],
  //     // cardonfile: [1111, 2222, 3333]
  //     notes: "Request you to pack clothes in separate bags",
  //     name: order.client_name,
  //     address: "247 West 87th Street NY 10024",
  //     phone: "+1 (646) 525-6754",
  //     payment: "7981",
  //     // status: 3,
  //     // pay_status: 0,
  //     order_created: "2022-04-04",
  //     desc: "SUMMER22",
  //     cust_img_note: cust_img_note,
  //     // add_order_data: {
  //     //   order_item: [{ item_name: "test", item_price: "12", item_weight: -1 }],
  //     // },
  //   },
  // });
  axios
    .post(
      `${base_url}${api.getOrderById}`,
      {
        // id: client_id,
        id: order_id,
      },
      {
        headers: {
          authorization: token,
        },
      }
    )
    .then((response) => response.data)
    .then((response) => {
      if (response.error) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        data: response,
      });
    });
};
export const getorder = (token, callback) => {
  axios
    .post(
      `${base_url}${api.getOrder}`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => res.data)
    .then((res) => {
      callback({
        ...res,
      });
    })
    .catch((err) => {
      getorder(token, callback);
    });
  axios.post(
    `${base_url}${api.getOrder}-full`,
    {},
    {
      headers: {
        Authorization: token,
      },
    }
  );
};
export const base64tolink = ({ base_64, name }, token, callback) => {
  axios
    .post(
      `${base_url}${api.base64ToLink}`,
      {
        base_64: base_64,
        name: name,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => res.data)
    .then((res) => {
      if (res.status == "ok") {
        callback({
          status: true,
          link: res.url,
          // clients: data.data.map((el) => {
          //   return {
          //     id: el.id,
          //     full_name: el.full_name,
          //     profile_photo_link: [...new Set(el.profile_photo_link)],
          //   };
          // }),
        });
      } else {
        callback({
          status: false,
        });
      }
      // getclients(token, (data) => {
      // });
    });
  // .catch((err) => {
  //   getorder(token, callback);
  // });
};
export const putorder = ({ id, data_to_save }, token, callback) => {
  axios
    .post(
      `${base_url}${api.putOrderById}`,
      {
        id: id,
        data: data_to_save,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => res.data)
    .then((res) => {
      if (res.status == "ok") {
        callback({
          status: true,
          link: res,
          // clients: data.data.map((el) => {
          //   return {
          //     id: el.id,
          //     full_name: el.full_name,
          //     profile_photo_link: [...new Set(el.profile_photo_link)],
          //   };
          // }),
        });
      } else {
        callback({
          status: false,
        });
      }
      // getclients(token, (data) => {
      // });
    });
  // .catch((err) => {
  //   getorder(token, callback);
  // });
};
export const chargeorder = (charge_obj, token, callback) => {
  axios
    .post(
      `${base_url}${api.chargeOrder}`,
      {
        ...charge_obj,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => res.data)
    .then((res) => {
      if (res.error) {
        if (["Card Debit Failed"].indexOf(res.error) != -1) {
          return callback({
            status: true,
            data: res.error,
            card_debit_error: true,
          });
        } else {
          return callback({
            status: false,
            // data: res,
          });
        }
      } else {
        callback({
          status: true,
          data: res,
        });
      }
      // getclients(token, (data) => {
      // });
    })
    .catch((err) => {
      callback({
        status: false,
        err_msg: "try again later",
      });
      // getorder(token, callback);
    });
};
export const complete_order = (delivery_complete_obj, token, callback) => {
  axios
    .post(
      `${base_url}${api.startDelivery}`,
      {
        ...delivery_complete_obj,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => res.data)
    .then((res) => {
      if (res.error) {
        return callback({
          status: false,
          data: res,
        });
      }
      return callback({
        status: true,
        data: res,
      });
    });
};
export const getcashlist = (token, callback) => {
  axios
    .post(
      `${base_url}${api.getCash}`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => res.data)
    .then((res) => {
      if (res.error) {
        return callback({
          status: false,
          // data: res,
        });
      }
      return callback({
        status: true,
        data: res,
      });
    });
};
export const editcashlist = (
  { id, full_name, img, amount, description, dateTime },
  token,
  callback
) => {
  axios
    .post(
      `${base_url}${api.editCash}`,
      { id, full_name, img, amount, description, dateTime },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => res.data)
    .then((res) => {
      if (res.error) {
        return callback({
          status: false,
          // data: res,
        });
      }
      return callback({
        status: true,
        data: res,
      });
    });
};
export const addcashlist = (
  { full_name, img, amount, description, dateTime },
  token,
  callback
) => {
  axios
    .post(
      `${base_url}${api.addCash}`,
      { full_name, img, amount, description, dateTime },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => res.data)
    .then((res) => {
      if (res.error) {
        return callback({
          status: false,
          // data: res,
        });
      }
      return callback({
        status: true,
        data: res,
      });
    });
};
export const complete_pickup = (obj, token, callback) => {
  axios
    .post(
      `${base_url}${api.startPickup}`,
      {
        ...obj,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => res.data)
    .then((res) => {
      if (res.error) {
        return callback({
          status: false,
          data: res,
        });
      }
      return callback({
        status: true,
        data: res,
      });
    });
};
export const update_faq = (arr, token, callback) => {
  axios
    .post(
      `${base_url}${api.setfaq}`,
      // `http://localhost:9000/writefile`,
      {
        ...arr,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => res.data)
    .then((res) => {
      if (res.error) {
        return callback({
          status: false,
          data: res,
        });
      }
      return callback({
        status: true,
        data: res,
      });
    });
};
export const get_faq = (arr, token, callback) => {
  axios
    .post(
      `${base_url}${api.getfaq}`,
      // `http://localhost:9000/readfile`,
      {
        ...arr,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => res.data)
    .then((res) => {
      if (res.error) {
        return callback({
          status: false,
          data: res,
        });
      }
      return callback({
        status: true,
        data: JSON.parse(res),
      });
    });
};
export const get_discount = (arr, token, callback) => {
  // return callback({
  //   status: true,
  //   data: [
  //     {
  //       id: 0,
  //       discount_txt: "test 1",
  //       is_active: true,
  //       is_deleted: false,
  //       is_for: {
  //         main: [0],
  //         sub_main: [2],
  //         items: [],
  //       },
  //       amount: "10",
  //       is_percentage: false,
  //       desc:
  //         // "1111111111111111111111111111111111111"
  //         "1"
  //           .split("")
  //           .map((el) => "dry cleaning all")
  //           .join(""),
  //     },
  //   ],
  // });
  axios
    .post(
      `${base_url}${api.getdiscount}`,
      {
        ...arr,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => res.data)
    .then((res) => {
      if (res.error) {
        return callback({
          status: false,
          data: res,
        });
      }
      return callback({
        status: true,
        data: JSON.parse(res),
      });
    });
};
export const update_discount = (arr, token, callback) => {
  axios
    .post(
      `${base_url}${api.setdiscount}`,
      {
        ...arr,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => res.data)
    .then((res) => {
      if (res.error) {
        return callback({
          status: false,
          data: res,
        });
      }
      return callback({
        status: true,
        // data: res,
      });
    });
};
// export const save_items = (obj, token, callback) => {
//   axios
//     .post(
//       `${base_url}${"save-items"}`,
//       {
//         ...obj,
//       },
//       {
//         headers: {
//           Authorization: token,
//         },
//       }
//     )
//     .then((res) => res.data)
//     .then((res) => {
//       if (res.error) {
//         return callback({
//           status: false,
//           data: res,
//         });
//       }
//       return callback({
//         status: true,
//         // data: res,
//       });
//     });
// };

export const get_vendors = (obj, token, callback) => {
  axios
    .post(
      `${base_url}${api.getvendors}`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => res.data)
    .then((res) => {
      if (res.error) {
        return callback({
          status: false,
          data: res,
        });
      }
      return callback({
        status: true,
        data: res,
      });
    });
};
export const add_vendors = (obj, token, callback) => {
  axios
    .post(
      `${base_url}${api.addvendors}`,
      {
        ...obj,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => res.data)
    .then((res) => {
      if (res.error) {
        return callback({
          status: false,
          data: res,
        });
      }
      return callback({
        status: true,
        data: res,
      });
    });
};
export const edit_vendors = (obj, token, callback) => {
  axios
    .post(
      `${base_url}${api.editvendors}`,
      {
        ...obj,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => res.data)
    .then((res) => {
      if (res.error) {
        return callback({
          status: false,
          data: res,
        });
      }
      return callback({
        status: true,
        data: res,
      });
    });
};
export const delete_vendors = (obj, token, callback) => {
  axios
    .post(
      `${base_url}${api.deletevendors}`,
      {
        ...obj,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => res.data)
    .then((res) => {
      if (res.error) {
        return callback({
          status: false,
          data: res,
        });
      }
      return callback({
        status: true,
        data: res,
      });
    });
};
export const getteam = (obj, token, callback) => {
  axios
    .post(
      `${base_url}${api.getteam}`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => res.data)
    .then((res) => {
      if (res.error) {
        return callback({
          status: false,
          data: res,
        });
      }
      return callback({
        status: true,
        data: res,
      });
    });
};
export const addteam = (obj, token, callback) => {
  axios
    .post(
      `${base_url}${api.addteam}`,
      { ...obj },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => res.data)
    .then((res) => {
      if (res.error) {
        return callback({
          status: false,
          data: res,
        });
      }
      return callback({
        status: true,
        data: res,
      });
    });
};
export const editteam = (obj, token, callback) => {
  axios
    .post(
      `${base_url}${api.editteamdetails}`,
      { ...obj },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => res.data)
    .then((res) => {
      if (res.error) {
        return callback({
          status: false,
          data: res,
        });
      }
      return callback({
        status: true,
        data: res,
      });
    });
};
export const edit_team_password = (obj, token, callback) => {
  axios
    .post(
      `${base_url}${api.deleteteampassword}`,
      { ...obj },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => res.data)
    .then((res) => {
      if (res.error) {
        return callback({
          status: false,
          data: res,
        });
      }
      return callback({
        status: true,
        data: res,
      });
    });
};
export const delete_team = (obj, token, callback) => {
  axios
    .post(
      `${base_url}${api.deleteteam}`,
      { ...obj },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => res.data)
    .then((res) => {
      if (res.error) {
        return callback({
          status: false,
          data: res,
        });
      }
      return callback({
        status: true,
        data: res,
      });
    });
};
export const checkpasssword = (obj, token, callback) => {
  axios
    .post(
      `${base_url}${api.checkpasssword}`,
      { ...obj },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => res.data)
    .then((res) => {
      if (res.error) {
        return callback({
          status: false,
          data: res,
        });
      }
      return callback({
        status: true,
        data: res,
      });
    });
};
export const save_items = (obj, token, callback) => {
  axios
    .post(
      `${base_url}${"save-items"}`,
      { ...obj },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => res.data)
    .then((res) => {
      if (res.error) {
        return callback({
          status: false,
          data: res,
        });
      }
      return callback({
        status: true,
        data: res,
      });
    });
};
export const add_client_withoutaddress = (obj, token, callback) => {
  axios
    .post(
      `${base_url}${api.addclient}`,
      { ...obj },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => res.data)
    .then((res) => {
      if (res.error) {
        return callback({
          status: false,
          data: res,
        });
      }
      return callback({
        status: true,
        data: res,
      });
    });
};
export const add_client_address = (obj, token, callback) => {
  axios
    .post(
      `${base_url}${api.addclientaddress}`,
      { ...obj },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => res.data)
    .then((res) => {
      if (res.error) {
        return callback({
          status: false,
          data: res,
        });
      }
      return callback({
        status: true,
        data: res,
      });
    });
};
export const add_client_options = (obj, token, callback) => {
  axios
    .post(
      `${base_url}${api.addclientoptions}`,
      { ...obj },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => res.data)
    .then((res) => {
      if (res.error) {
        return callback({
          status: false,
          data: res,
        });
      }
      return callback({
        status: true,
        data: res,
      });
    });
};
export const set_archive = (obj, token, callback) => {
  axios
    .post(
      `${base_url}${"set-archived"}`,
      { ...obj },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => res.data)
    .then((res) => {
      if (res.error) {
        return callback({
          status: false,
          data: res,
        });
      }
      return callback({
        status: true,
        data: res,
      });
    });
};
export const chargenow = (charge_obj, token, callback) => {
  axios
    .post(
      `${base_url}${api.chargeamount}`,
      {
        ...charge_obj,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => res.data)
    .then((res) => {
      // console.log(res);
      if (res.error) {
        if (["Card Debit Failed"].indexOf(res.error) != -1) {
          return callback({
            status: true,
            data: res.error,
            card_debit_error: true,
          });
        } else {
          return callback({
            status: false,
            // data: res,
          });
        }
      } else {
        callback({
          status: true,
          data: res,
        });
      }
      // getclients(token, (data) => {
      // });
    })
    .catch((err) => {
      callback({
        status: false,
        err_msg: "try again later",
      });
      // getorder(token, callback);
    });
};

export const add_vendor_report = (obj, token, callback) => {
  axios
    .post(
      `${base_url}${"add-vendor-report"}`,
      { ...obj },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => res.data)
    .then((res) => {
      if (res.error) {
        return callback({
          status: false,
          data: res,
        });
      }
      return callback({
        status: true,
        data: res,
      });
    });
};
export const update_vendor_report = (obj, token, callback) => {
  axios
    .post(
      `${base_url}${"edit-vendor-report"}`,
      { ...obj },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => res.data)
    .then((res) => {
      if (res.error) {
        return callback({
          status: false,
          data: res,
        });
      }
      return callback({
        status: true,
        data: res,
      });
    });
};
export const delete_vendor_report = (obj, token, callback) => {
  axios
    .post(
      `${base_url}${"edit-vendor-report"}`,
      { ...obj },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => res.data)
    .then((res) => {
      if (res.error) {
        return callback({
          status: false,
          data: res,
        });
      }
      return callback({
        status: true,
        data: res,
      });
    });
};
